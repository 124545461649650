import { APP_URI } from '@crone/shared/config/constants';

export const logoutHelper = () => {
  if (typeof window !== 'undefined' && window.location) {
    let escapedPath = '';
    if (
      window.location.pathname &&
      window.location.pathname?.trim()?.length > 1
    ) {
      escapedPath = encodeURIComponent(window.location.pathname);
    }
    // setCookie('loggedIn', 'false', { path: '/', sameSite: 'lax' });
    window.location.href = `${APP_URI}/login?from=${escapedPath}`;
  } else if (typeof window !== 'undefined') {
    window.location.href = `${APP_URI}/login`;
  }
};

export const redirectHelper = (path: string) => {
  if (typeof window !== 'undefined' && window.location) {
    // setCookie('loggedIn', 'false', { path: '/', sameSite: 'lax' });
    // use expo router navigation
    window.location.href = `${window.location.origin}${path}`;
  }
};
